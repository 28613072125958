import { getHost } from 'services/EnvProvider';

export const isSimpleMiningUrl = () => getHost().includes('simplemining');

export const homePageUrl = () => (isSimpleMiningUrl() ? '/mining' : '/');

export const getDomainName = () =>
  isSimpleMiningUrl() ? 'SimpleMining.net' : 'SimplePod.ai';

export const getMetaTranslationKey = () =>
  isSimpleMiningUrl() ? 'simpleMining' : 'simplePod';
