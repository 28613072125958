import classNames from 'classnames';
import { useState, useEffect } from 'react';

interface Props {
  squareSideLengthRelativeToScreen?: number;
  positionHorizontal?: 'center' | 'left' | 'right';
  positionVertical?: 'center' | 'top' | 'bottom';
}

const GlowingSquare = (props: Props) => {
  const {
    squareSideLengthRelativeToScreen = 50,
    positionHorizontal = 'center',
    positionVertical = 'center',
  } = props;

  const [squareHeight, setSquareHeight] = useState<number>(0);

  const positionClass = classNames('glowing-square', {
    'glowing-square--horizontal-center': positionHorizontal === 'center',
    'glowing-square--horizontal-left': positionHorizontal === 'left',
    'glowing-square--horizontal-right': positionHorizontal === 'right',
    'glowing-square--vertical-center': positionVertical === 'center',
    'glowing-square--vertical-top': positionVertical === 'top',
    'glowing-square--vertical-bottom': positionVertical === 'bottom',
  });

  const squareSideLength =
    (squareSideLengthRelativeToScreen / 100) * squareHeight;

  useEffect(() => {
    const htmlWidth = document.body.clientWidth;

    const squareHeight = Math.min(window.innerHeight, htmlWidth / 1.5);

    setSquareHeight(squareHeight);
  }, []);

  return (
    <div
      className={positionClass}
      style={{
        width: `${squareSideLength}px`,
        height: `${squareSideLength}px`,
        maxHeight: `${squareHeight}px`,
        maxWidth: `${squareHeight}px`,
      }}
    >
      <div className="glowing-square__inner" />
    </div>
  );
};

export default GlowingSquare;
