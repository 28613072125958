import classNames from 'classnames';

const SpBrandName = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('sp-brand-name', className);

  return (
    <svg viewBox="0 0 618.08 146.46" className={classes} {...attributes}>
      <defs>
        <style>{'.cls-text{fill: #fff;}'}</style>
      </defs>
      <path
        className="cls-text"
        d="M27.86,115.08c-5.98,0-11.05-1.08-15.23-3.24-4.18-2.16-7.92-5.33-11.23-9.5l8.42-8.42c2.09,3.02,4.57,5.4,7.45,7.13,2.88,1.66,6.55,2.48,11.02,2.48,4.18,0,7.49-.86,9.94-2.59,2.52-1.73,3.78-4.1,3.78-7.13,0-2.52-.65-4.57-1.94-6.16-1.3-1.58-3.02-2.88-5.18-3.89-2.09-1.08-4.43-2.02-7.02-2.81-2.52-.86-5.08-1.8-7.67-2.81-2.52-1.08-4.86-2.41-7.02-4-2.09-1.58-3.78-3.64-5.08-6.16-1.3-2.52-1.94-5.72-1.94-9.61,0-4.46,1.08-8.24,3.24-11.34,2.23-3.17,5.22-5.58,8.96-7.24,3.74-1.66,7.92-2.48,12.53-2.48,4.97,0,9.4.97,13.28,2.92,3.96,1.94,7.16,4.43,9.61,7.45l-8.42,8.42c-2.16-2.45-4.43-4.25-6.8-5.4-2.3-1.22-4.97-1.84-7.99-1.84-3.74,0-6.7.76-8.86,2.27-2.09,1.51-3.13,3.64-3.13,6.37,0,2.3.65,4.18,1.94,5.62,1.3,1.37,2.99,2.56,5.08,3.56,2.16.94,4.5,1.84,7.02,2.7,2.59.86,5.15,1.84,7.67,2.92,2.59,1.08,4.93,2.48,7.02,4.21,2.16,1.66,3.89,3.82,5.18,6.48,1.3,2.66,1.94,6.01,1.94,10.04,0,6.77-2.41,12.13-7.24,16.09-4.75,3.96-11.2,5.94-19.33,5.94Z"
      />
      <path
        className="cls-text"
        d="M65.3,114v-51.84h11.88v51.84h-11.88ZM71.24,52.66c-2.02,0-3.71-.68-5.08-2.05-1.3-1.37-1.94-3.06-1.94-5.08s.65-3.71,1.94-5.08c1.37-1.37,3.06-2.05,5.08-2.05s3.78.68,5.08,2.05,1.94,3.06,1.94,5.08-.65,3.71-1.94,5.08c-1.3,1.37-2.99,2.05-5.08,2.05Z"
      />
      <path
        className="cls-text"
        d="M90.5,114v-51.84h11.88v51.84h-11.88ZM124.95,114v-30.89c0-3.46-1.08-6.12-3.24-7.99-2.16-1.94-4.82-2.92-7.99-2.92-2.16,0-4.1.43-5.83,1.3-1.66.86-2.99,2.12-4,3.78-1.01,1.58-1.51,3.53-1.51,5.83l-4.64-2.59c0-3.96.86-7.38,2.59-10.26,1.73-2.88,4.07-5.11,7.02-6.7,2.95-1.66,6.26-2.48,9.94-2.48s6.98.79,9.94,2.38,5.29,3.82,7.02,6.7c1.73,2.88,2.59,6.34,2.59,10.37v33.48h-11.88ZM159.41,114v-30.89c0-3.46-1.08-6.12-3.24-7.99-2.16-1.94-4.82-2.92-7.99-2.92-2.09,0-4,.43-5.72,1.3-1.73.86-3.1,2.12-4.1,3.78-1.01,1.58-1.51,3.53-1.51,5.83l-6.7-2.59c.36-3.96,1.51-7.38,3.46-10.26,1.94-2.88,4.43-5.11,7.45-6.7,3.1-1.66,6.48-2.48,10.15-2.48s7.13.79,10.15,2.38c3.02,1.58,5.44,3.82,7.24,6.7,1.8,2.88,2.7,6.34,2.7,10.37v33.48h-11.88Z"
      />
      <path
        className="cls-text"
        d="M211.28,115.08c-3.96,0-7.49-.83-10.58-2.48-3.1-1.73-5.54-4.07-7.34-7.02-1.73-2.95-2.59-6.3-2.59-10.04v-14.9c0-3.74.9-7.09,2.7-10.04,1.8-2.95,4.25-5.26,7.34-6.91,3.1-1.73,6.59-2.59,10.48-2.59,4.68,0,8.89,1.22,12.64,3.67,3.82,2.38,6.8,5.62,8.96,9.72,2.23,4.03,3.35,8.6,3.35,13.72s-1.12,9.61-3.35,13.72c-2.16,4.03-5.15,7.24-8.96,9.61-3.74,2.38-7.96,3.56-12.64,3.56ZM183.52,135.6V62.16h11.88v13.93l-2.05,12.64,2.05,12.53v34.34h-11.88ZM209.34,103.85c2.88,0,5.4-.65,7.56-1.94,2.23-1.37,3.96-3.24,5.18-5.62,1.3-2.38,1.94-5.11,1.94-8.21s-.65-5.83-1.94-8.21c-1.22-2.38-2.95-4.21-5.18-5.51-2.16-1.37-4.68-2.05-7.56-2.05s-5.44.68-7.67,2.05c-2.23,1.3-3.96,3.13-5.18,5.51-1.22,2.38-1.84,5.11-1.84,8.21s.61,5.83,1.84,8.21,2.95,4.25,5.18,5.62c2.23,1.3,4.79,1.94,7.67,1.94Z"
      />
      <path className="cls-text" d="M246.17,114V36.24h11.88v77.76h-11.88Z" />
      <path
        className="cls-text"
        d="M295.36,115.08c-5.18,0-9.86-1.15-14.04-3.46-4.1-2.38-7.38-5.62-9.83-9.72-2.38-4.1-3.56-8.71-3.56-13.82s1.19-9.68,3.56-13.72c2.38-4.1,5.58-7.34,9.61-9.72,4.1-2.38,8.64-3.56,13.61-3.56s9.07,1.12,12.74,3.35c3.74,2.23,6.66,5.29,8.75,9.18,2.16,3.89,3.24,8.32,3.24,13.28,0,.86-.07,1.76-.22,2.7-.07.86-.22,1.84-.43,2.92h-42.55v-9.72h36.4l-4.43,3.89c-.14-3.17-.76-5.83-1.84-7.99-1.08-2.16-2.63-3.82-4.64-4.97-1.94-1.15-4.36-1.73-7.24-1.73-3.02,0-5.65.65-7.88,1.94s-3.96,3.13-5.18,5.51c-1.22,2.3-1.84,5.08-1.84,8.32s.65,6.08,1.94,8.53c1.3,2.45,3.13,4.36,5.51,5.72,2.38,1.3,5.11,1.94,8.21,1.94,2.66,0,5.11-.47,7.34-1.4,2.3-.94,4.25-2.3,5.83-4.1l7.56,7.67c-2.52,2.95-5.58,5.18-9.18,6.7-3.6,1.51-7.42,2.27-11.45,2.27Z"
      />
      <path
        className="cls-text"
        d="M336.96,84.19v-7.02h21.92c3.02,0,5.72-.61,8.1-1.84,2.45-1.22,4.36-2.95,5.72-5.18,1.44-2.3,2.16-5.04,2.16-8.21s-.72-5.87-2.16-8.1c-1.37-2.3-3.28-4.07-5.72-5.29-2.38-1.3-5.08-1.94-8.1-1.94h-21.92v-6.91h22.46c4.46,0,8.46.94,11.99,2.81,3.53,1.8,6.3,4.36,8.32,7.67,2.09,3.31,3.13,7.24,3.13,11.77s-1.04,8.39-3.13,11.77c-2.02,3.31-4.79,5.9-8.32,7.78-3.53,1.8-7.52,2.7-11.99,2.7h-22.46ZM331.88,114V39.7h7.88v74.3h-7.88Z"
      />
      <path
        className="cls-text"
        d="M415.49,115.08c-4.9,0-9.36-1.19-13.39-3.56-3.96-2.38-7.13-5.58-9.5-9.61-2.3-4.03-3.46-8.53-3.46-13.5s1.15-9.32,3.46-13.28c2.38-3.96,5.54-7.09,9.5-9.4,4.03-2.38,8.5-3.56,13.39-3.56s9.43,1.15,13.39,3.46c3.96,2.3,7.09,5.47,9.4,9.5,2.38,3.96,3.56,8.39,3.56,13.28s-1.19,9.58-3.56,13.61c-2.3,3.96-5.44,7.13-9.4,9.5-3.96,2.38-8.42,3.56-13.39,3.56ZM415.49,107.74c3.6,0,6.77-.83,9.5-2.48,2.81-1.66,5-3.96,6.59-6.91,1.66-2.95,2.48-6.26,2.48-9.94s-.83-6.91-2.48-9.72c-1.58-2.88-3.78-5.15-6.59-6.8-2.74-1.66-5.9-2.48-9.5-2.48s-6.7.83-9.5,2.48c-2.81,1.66-5,3.92-6.59,6.8-1.58,2.81-2.38,6.05-2.38,9.72s.79,7.09,2.38,10.04c1.58,2.88,3.78,5.15,6.59,6.8,2.81,1.66,5.98,2.48,9.5,2.48Z"
      />
      <path
        className="cls-text"
        d="M475.47,115.08c-4.68,0-8.89-1.15-12.64-3.46-3.74-2.3-6.73-5.44-8.96-9.4-2.16-4.03-3.24-8.53-3.24-13.5s1.08-9.54,3.24-13.5c2.23-4.03,5.22-7.2,8.96-9.5,3.74-2.38,7.96-3.56,12.64-3.56,3.82,0,7.27.76,10.37,2.27,3.1,1.51,5.65,3.67,7.67,6.48,2.09,2.74,3.31,5.98,3.67,9.72v15.88c-.43,3.67-1.66,6.91-3.67,9.72-1.94,2.74-4.46,4.9-7.56,6.48s-6.59,2.38-10.48,2.38ZM476.76,107.84c3.53,0,6.62-.79,9.29-2.38,2.66-1.66,4.75-3.92,6.26-6.8,1.51-2.95,2.27-6.3,2.27-10.04s-.79-7.16-2.38-10.04c-1.51-2.88-3.6-5.15-6.26-6.8-2.66-1.66-5.76-2.48-9.29-2.48s-6.66.83-9.4,2.48c-2.74,1.66-4.9,3.96-6.48,6.91-1.51,2.88-2.27,6.19-2.27,9.94s.76,7.09,2.27,10.04c1.58,2.88,3.74,5.15,6.48,6.8,2.81,1.58,5.98,2.38,9.5,2.38ZM501.71,114h-7.56v-13.61l1.51-12.53-1.51-12.2v-38.12h7.56v76.46Z"
      />
      <path
        className="cls-text"
        d="M567.87,115.08c-4.82,0-9.14-1.15-12.96-3.46-3.82-2.38-6.84-5.54-9.07-9.5-2.16-3.96-3.24-8.42-3.24-13.39s1.08-9.54,3.24-13.5c2.23-4.03,5.26-7.2,9.07-9.5,3.82-2.38,8.1-3.56,12.85-3.56,3.89,0,7.34.79,10.37,2.38,3.1,1.51,5.58,3.67,7.45,6.48,1.94,2.74,3.13,5.94,3.56,9.61v15.88c-.43,3.67-1.62,6.91-3.56,9.72-1.87,2.81-4.36,5-7.45,6.59-3.02,1.51-6.44,2.27-10.26,2.27ZM569.06,107.84c5.26,0,9.47-1.76,12.64-5.29,3.24-3.53,4.86-8.17,4.86-13.93,0-3.82-.76-7.16-2.27-10.04-1.44-2.95-3.49-5.22-6.16-6.8-2.59-1.66-5.65-2.48-9.18-2.48s-6.8.83-9.61,2.48c-2.74,1.66-4.93,3.96-6.59,6.91-1.58,2.88-2.38,6.19-2.38,9.94s.79,7.06,2.38,9.94c1.66,2.88,3.89,5.15,6.7,6.8,2.81,1.66,6.01,2.48,9.61,2.48ZM586.12,114v-13.61l1.51-12.53-1.51-12.2v-12.42h7.56v50.76h-7.56Z"
      />
      <path
        className="cls-text"
        d="M609.01,114v-50.76h7.67v50.76h-7.67ZM612.79,52.22c-1.44,0-2.66-.5-3.67-1.51-1.01-1.08-1.51-2.34-1.51-3.78,0-1.51.51-2.77,1.51-3.78s2.23-1.51,3.67-1.51c1.58,0,2.84.5,3.78,1.51,1.01,1.01,1.51,2.27,1.51,3.78s-.51,2.7-1.51,3.78c-.94,1.01-2.2,1.51-3.78,1.51Z"
      />
      <path
        className="cls-text"
        d="M609.12,50.71c1.01,1.01,2.23,1.51,3.67,1.51,1.58,0,2.84-.5,3.78-1.51,1.01-1.08,1.51-2.34,1.51-3.78,0-1.51-.51-2.77-1.51-3.78-.94-1.01-2.2-1.51-3.78-1.51-1.44,0-2.66.5-3.67,1.51s-1.51,2.27-1.51,3.78.51,2.7,1.51,3.78Z"
      />
      <path
        className="cls-text"
        d="M521.1,112.07c1.01,1.01,2.23,1.51,3.67,1.51,1.58,0,2.84-.5,3.78-1.51,1.01-1.08,1.51-2.34,1.51-3.78,0-1.51-.5-2.77-1.51-3.78-.94-1.01-2.2-1.51-3.78-1.51-1.44,0-2.66.5-3.67,1.51-1.01,1.01-1.51,2.27-1.51,3.78s.5,2.7,1.51,3.78Z"
      />
    </svg>
  );
};

export default SpBrandName;
