import { NextPageContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import MiningAppView from 'components/Layout/Mining/MiningAppView';
import MiningHardwareDevices from 'components/Layout/Mining/MiningHardwareDevices';
// import MiningDeployment from 'components/Layout/Mining/MiningDeployment';
import MiningFlashDrive from 'components/Layout/Mining/MiningFlashDrive';
import MiningFeaturesPresentation from 'components/Layout/Mining/MiningFeaturesPresentation';
import MiningFeaturesListSwiper from 'components/Layout/Mining/MiningFeaturesListSwiper';
import MiningMinerPrograms from 'components/Layout/Mining/MiningMinerPrograms';
import MiningGpusBrands from 'components/Layout/Mining/MiningGpusBrands';
import StartMining from 'components/Layout/Mining/StartMining';
import MiningSeoDescription from 'components/Layout/Mining/MiningSeoDescription';
import MiningPageHero from 'components/Layout/Mining/MiningPageHero';
import Pricing from 'components/Layout/Pricing';
import DownloadTabs from 'components/Layout/Download/DownloadTabs';
import { getRequest } from 'utils/request';
import { DataOsImage, OsImage } from 'types/OsImageList';

interface Props {
  data: OsImage[];
}

const Mining = (props: Props) => {
  const { data = [] } = props;
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>
          {t('mining.meta.title') || 'Best GPU Mining OS | SimpleMining.net'}
        </title>
        <meta name="keywords" content={t('mining.meta.keywords')} />
        <meta name="description" content={t('mining.meta.description')} />
      </Head>
      <MiningPageHero />
      <MiningAppView />
      <MiningSeoDescription />
      <MiningHardwareDevices />
      {/* <MiningDeployment /> */}
      <MiningFlashDrive />
      <MiningFeaturesPresentation />
      <MiningFeaturesListSwiper />
      <MiningMinerPrograms />
      <MiningGpusBrands />
      <Pricing />
      <DownloadTabs data={data} />
      <StartMining />
    </>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

export async function getStaticProps(context: NextPageContext) {
  const { locale = 'en' } = context;
  let data = [];

  try {
    const response = await getRequest('/os-images');

    if (response.data) {
      data = response.data.map((dataElement: DataOsImage) =>
        dataElement.translations?.[locale]
          ? { ...dataElement, ...dataElement.translations[locale] }
          : { ...dataElement, ...dataElement.translations.en },
      );
    }
  } catch (error) {
    console.error(error);
  }

  return {
    props: {
      data,
      ...(await serverSideTranslations(locale)),
    },
    revalidate: 60,
  };
}

export default Mining;
