import GlowingSquare from 'components/Layout/GlowingSquare';
import RectangleGrid from 'images/svg/backgroundElements/RectangleGrid';
import ImageWrapper from 'components/ImageWrapper';

const MiningAppView = () => {
  return (
    <div className="app-view">
      <div className="app-view__img-wrapper">
        <ImageWrapper
          src="/img/smAppBackground/sm-app-screen.webp"
          alt="Simple Mining app"
          width={5079}
          height={3701}
          layout="responsive"
          sizes="80vw"
          priority
        />
      </div>
      <RectangleGrid />
      <GlowingSquare
        squareSideLengthRelativeToScreen={30}
        positionHorizontal="center"
        positionVertical="bottom"
      />
    </div>
  );
};

export default MiningAppView;
