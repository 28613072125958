import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import 'styles/style.scss';
import GlobalContext from 'context/GlobalContext';
import PageContentWrapper from 'components/Layout/PageContentWrapper';
import NavigationBar from 'components/Layout/NavigationBar';
import Footer from 'components/Layout/Footer/Footer';
import CookiesConsent from 'components/CookiesConsent';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Custom404 from 'pages/404';
import { isSimpleMiningUrl } from 'utils/isSimpleMiningUrl';

// eslint-disable-next-line no-restricted-imports
import nextI18nextConfig from '../../next-i18next.config.js';

const miningSubpages = ['mining', 'hiveos', 'minerstat'];

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  const shouldReturn404 =
    !isSimpleMiningUrl() &&
    miningSubpages.some(el => router.asPath.includes(el));

  const [hash, setHash] = useState('');

  useEffect(() => {
    let userLocale: string = localStorage.getItem('SM_USER_LOCALE') || 'en';

    if (!router.locales?.includes(userLocale.toLowerCase())) {
      userLocale = 'en';

      localStorage.setItem('SM_USER_LOCALE', userLocale);
    }

    if (userLocale == router.defaultLocale) {
      router.push(router.asPath);

      return;
    }

    userLocale && router.push(`/${userLocale}${router.asPath}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentHash = router.asPath.split('#')[1];

    if (currentHash !== hash) {
      setHash(currentHash);
      document.getElementById(currentHash)?.scrollIntoView();
    }
  }, [router.asPath, hash]);

  return (
    <GlobalContext>
      <PageContentWrapper>
        <NavigationBar />
        {shouldReturn404 ? <Custom404 /> : <Component {...pageProps} />}
        <Footer />
      </PageContentWrapper>
      <CookiesConsent />
    </GlobalContext>
  );
};

export default appWithTranslation(App, nextI18nextConfig);
